<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/userInformation' }">會員資料</el-breadcrumb-item>
                <el-breadcrumb-item>編輯商戶</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="container">
            <div class="form-box">
                <div class="form-title">編輯商戶</div>
                <el-form ref="formName"
                         :model="form"
                         :rules="rules"
                         label-width="125px"
                         label-position="left">
                    <el-form-item label="商戶ID">
                        <el-col>{{form.id}}</el-col>
                    </el-form-item>
                    <el-form-item label="商戶名稱"
                                  prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="聯絡人"
                                  prop="contact_person">
                        <el-input v-model="form.contact_person"></el-input>
                    </el-form-item>
                    <el-form-item label="聯絡方式(手機)"
                                  prop="phone_number">
                        <el-select v-model="form.area_code"
                                   placeholder="請選擇"
                                   class="phone-select">
                            <el-option v-for="item in options"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>

                        </el-select>
                        <el-input class="phone-input"
                                  v-model="form.phone_number"></el-input>
                    </el-form-item>
                    <el-form-item label="聯絡方式(e-mail)"
                                  prop="email">
                        <el-input v-model="form.email"></el-input>
                    </el-form-item>
                    <el-form-item label="聯絡方式(其他)">
                        <div v-for="(item, index) in form.other_contact "
                             :key="index"
                             class="contact">
                            <el-select v-model="item.type"
                                       placeholder="請選擇"
                                       class="phone-select">
                                <el-option v-for="item in contactOptions"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>

                            </el-select>
                            <el-input style="padding:0 15px "
                                      v-model="item.contact"></el-input>
                            <el-button v-if="index==0"
                                       icon="el-icon-circle-plus-outline"
                                       size="mini"
                                       @click="addDomain"
                                       circle></el-button>
                            <el-button v-else
                                       icon="el-icon-remove-outline"
                                       size="mini"
                                       type="danger"
                                       @click="removeDomain(index)"
                                       circle></el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="帳號">
                        <el-col>{{form.account}}</el-col>
                    </el-form-item>
                    <el-form-item label="密碼"
                                  prop="password">
                        <el-input placeholder="請輸入密碼,長度限制最小 4 最多 100 字元"
                                  v-model="form.password"
                                  show-password></el-input>
                    </el-form-item>
                    <div class="submit-btn">
                        <el-button type="primary"
                                   @click="onSubmit('formName')">表單提交</el-button>
                        <el-button @click="goBack">取消</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { memberUpdateList, memberUpdate } from '../../api/index'
export default {
    name: 'memberEdit',
    data() {
        var checkEmail = (rule, value, callback) => {
            const mailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
            if (!value) {
                return callback(new Error('郵箱不能為空'))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('請輸入正確的郵箱格式'))
                }
            }, 100)
        }
        return {
            form: { other_contact: [{ type: '', contact: '' }] },
            finalForm: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: '請輸入商戶名稱',
                        trigger: 'blur',
                    },
                ],
                contact_person: [
                    {
                        required: true,
                        message: '請輸入聯絡人',
                        trigger: 'blur',
                    },
                ],
                phone_number: [
                    {
                        required: true,
                        message: '請輸入手機號',
                        trigger: 'blur',
                    },
                ],
                email: [{ required: true, validator: checkEmail, trigger: 'blur' }],
                account: [
                    {
                        required: true,
                        message: '請輸入帳號',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '請輸入密碼',
                        trigger: 'blur',
                    },
                ],
            },
            options: [
                {
                    value: '886',
                    label: '886',
                },
                {
                    value: '86',
                    label: '86',
                },
            ],
            contactOptions: [
                {
                    value: 'QQ',
                    label: 'QQ',
                },
                {
                    value: '微信',
                    label: '微信',
                },
                {
                    value: 'Telegram',
                    label: 'Telegram',
                },
            ],
        }
    },
    created() {},
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            memberUpdateList({ id: this.$route.query.id })
                .then((res) => {
                    if (res.code == 300) {
                        this.form = res.data.data_info
                        this.form.other_contact = res.data.other_contact
                    }
                })
                .catch(() => {
                    this.$message.error('失敗')
                })
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.finalForm = { ...this.form }
                    this.finalForm.other_contact = JSON.stringify(this.finalForm.other_contact) //這個要傳json
                    //判斷手機台灣去0
                    if (typeof this.finalForm.phone_number != 'undefined') {
                        this.finalForm.phone_number = this.finalForm.phone_number.replace(/\b(0+)/gi, '')
                    }
                    memberUpdate(this.finalForm)
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success('提交成功！')
                                this.$router.push({ path: 'userInformation' })
                            } else if (res.code == 500) {
                                this.$message.error(res.message)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            this.$message.error('失敗')
                        })
                } else {
                    this.$message.error('失敗')
                    return false
                }
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        //新增其他
        addDomain() {
            if (this.form.other_contact.length < 5) {
                this.form.other_contact.push({ type: 'QQ', contact: '' })
            }
        },
        //刪除其他
        removeDomain(index) {
            if (index !== -1) {
                this.form.other_contact.splice(index, 1)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.form-box {
    margin: auto;
    width: 100%;
}
.form-title {
    text-align: center;
    font-size: 18px;
    padding-bottom: 20px;
}
.phone-input {
    width: 50%;
    padding: 0px 0px 0px 15px;
}
.phone-select {
    width: 20%;
    min-width: 80px;
}
.submit-btn {
    justify-content: center;
    display: flex;
    align-items: center;
}
.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.add-contact {
    position: absolute;
    right: -35px;
    top: 7px;
}
@media screen and (min-width: 768px) {
    .form-box {
        width: 600px;
    }
}
</style>